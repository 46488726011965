<template>
  <div class="recipe-item-page">
    <div class="columns item-body">
      <div class="column col-6 col-sm-12 form-group">
        <dx-autocomplete
          id="drug"
          v-model="drug"
          :source="findDrug"
          :custom-label="setName"
          @blur="onDrugName"
          @select="setDrug"
          :debounce="800"
          placeholder="Pesquisar medicamento">
          <button slot="action" class="btn btn-gray btn-action input-group-btn btn-icon"
                  @click="remove">
            <fa-icon :icon="['fal', 'times']"/>
          </button>
          <template v-slot="{ item }">
            <strong class="text-primary">
              <fa-icon :icon="['fal', 'capsules']"></fa-icon>
              {{ item.name }}
            </strong>
            <small v-if="item.caption">{{ item.caption }}</small>
          </template>
        </dx-autocomplete>
      </div>
      <div class="column col-6 col-sm-12 form-group">
        <div class="input-group">
          <dx-input-number class="text-center" v-model="item.quantity"
                           :precision="2" placeholder="Qtd."
                           :disabled="disableQuantity"/>
          <select class="form-select" v-model="item.unit" @change="changeUnit">
            <option v-for="(text, value, i) in units" :key="i"
                    :value="value">{{ text }}</option>
          </select>
          <select class="form-select" v-model="item.access">
            <option v-for="(text, value, i) in accesses" :key="i"
                    :value="value">{{ text }}</option>
          </select>
        </div>
      </div>
      <div class="column col-12">
        <textarea rows="2" class="form-input"
                  v-model="item.dosage" placeholder="Posologia"/>
      </div>
      <div class="column col-12">
        <span class="hover-link" @click="dosage.show = true">
          <fa-icon class="mt-1" :icon="['fal', 'pencil']"/>
          <small class="ml-1">Minhas posologias</small>
        </span>
        <label class="form-checkbox float-right">
          <input type="checkbox" v-model="item.specialControl">
          <i class="form-icon"></i>Controle especial
        </label>
      </div>
      <div class="divider"></div>
    </div>

    <dx-modal title="Minhas Posologias" @input="dosage.show = false"
              id="modal-dosage" :value="dosage.show">
      <div class="empty mt-2" v-if="dosages && dosages.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
        </div>
        <p class="empty-title h6">Minhas Posologias</p>
        <p class="empty-subtitle">
          Nenhuma posologia encontrada.
          Conforme for realizando os atendimentos as informações serão disponibilizadas.
        </p>
      </div>
      <table class="table table-striped table-hover" v-else>
        <thead>
        <tr>
          <th>Selecione</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(dosage, i) in dosages" :key="i">
          <td>
            <span class="hover-link" @click="selectDosage(dosage)">{{ dosage.value }}</span>
          </td>
          <td class="text-right">
            <button class="btn btn-icon btn-action btn-sm btn-gray-outline"
                    @click="removeDosage(dosage.id, i)">
              <fa-icon :icon="['fal', 'times']"/>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn" @click="dosage.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import { accesses } from 'src/data/drug-accesses';
import { units } from 'src/data/drug-units';

export default {
  props: {
    dosages: {
      type: Array,
    },
    data: {
      id: {
        type: String,
      },
      name: {
        type: String,
      },
      originalName: {
        type: String,
      },
      quantity: {
        type: Number,
        default: 1,
      },
      unit: {
        type: String,
      },
      access: {
        type: String,
      },
      dosage: {
        type: String,
      },
      specialControl: {
        type: Boolean,
      },
    },
  },
  data() {
    return {
      units,
      accesses,
      drug: null,
      disableQuantity: false,
      dosage: {
        show: false,
      },
      item: {
        id: '',
        name: '',
        originalName: '',
        quantity: 1,
        unit: '',
        access: '',
        dosage: '',
        specialControl: false,
      },
    };
  },
  mounted() {
    this.item = this.data;
    this.drug = this.data;
  },
  methods: {
    changeUnit() {
      this.disableQuantity = false;
      if (this.item.unit === 'continuous') {
        this.item.quantity = 1;
        this.disableQuantity = true;
      }
    },
    selectDosage(dosage) {
      const found = this.item.dosage.trim();
      this.item.dosage += `${found ? '\n' : ''}${dosage.value}`;
      this.dosage.show = false;
    },
    removeDosage(id, idx) {
      this.$emit('removeDosage', { id, idx });
    },
    remove() {
      this.$emit('remove');
    },
    removeDrug(idx) {
      this.data.drugs.splice(idx, 1);
    },
    findDrug(search) {
      return this.$http.get(`/medicines?search=${search}`)
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [{ id: null, name: search }];
          }
          return data.items;
        });
    },
    setDrug(data) {
      this.item.id = data.id;
      this.item.name = `${data.name} ${data.caption || ''}`;
      this.item.originalName = data.name;
      this.item.quantity = 1;
      this.item.unit = data.unit || 'packing';
      this.item.access = data.access || 'oral';
      this.drug = {
        id: this.item.id,
        name: this.item.name,
      };
    },
    setName(item) {
      return item.name;
    },
    onDrugName(text) {
      this.item.name = text;
    },
  },
};
</script>
